// 设备预警信息表头
export const warningColumns = () => {
  return [
    {
      title: '序号',
      width: 50,
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
     {
       title: '预警对象',
       width: 120,
       ellipsis: true,
       align: 'center',
       dataIndex: 'pointName',
     },
    {
      title: '预警内容',
      width: 250,
      align: 'center',
      ellipsis: true,
      dataIndex: 'title',
    },
    {
      title: '指标值/状态',
      width: 150,
      align: 'center',
      scopedSlots: { customRender: 'currentMetricsValue' },
    },
    // {
    //   title: '预警值(范围)',
    //   width: 100,
    //   align: 'center',
    //   dataIndex: 'metricsRange',
    //   scopedSlots: { customRender: 'metricsRange' }
    // },
    {
      title: '采集时间',
      width: 120,
      ellipsis: true,
      align: 'center',
      scopedSlots: { customRender: 'collectTime' },
    },
    {
      title: '预警持续时间',
      width: 150,
      ellipsis: true,
      align: 'center',
      dataIndex: 'warningLastTime',
    },
    {
      title: '类型',
      width: 50,
      align: 'center',
      scopedSlots: { customRender: 'warningType' },
    },
    // {
    //   title: '处理状态',
    //
    //   align: 'center',
    //   scopedSlots: { customRender: 'warningDealStatus' },
    // },
    // {
    //   title: '处理时间',
    //   ellipsis: true,
    //   align: 'center',
    //   scopedSlots: { customRender: 'dealEndTime' },
    // },
    // {
    //   title: '处理人',
    //
    //   align: 'center',
    //   dataIndex: 'dealUserName',
    //   customRender: (text, record) => {
    //     const time  = `${record.dealUserName ? record.dealUserName : '-'}` //es6写法
    //     return time
    //   },
    // },
    {
      title: '操作',
      width: 50,
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}

export const warningFormVO = () => {
  return {
    id: '',
    title: '',
    currentMetricsValue: '',
    warningMetricsValue: '',
    metricsRange: '',
    dateCreated: '',
    collectTime: '',
    warningLastTime: '',
    warningType: '',
    dealEndTime: '',
    dealUserName: 0,
    warningDealStatus: '',
    dealUserId: '',
    remark: '',
    deviceName: '',
    pointName: ''
  }
}

const common = {
  methods: {
    formatCurrentMetricsValue(item) {
      if (item.metricsRange === '-') {
        return item.currentMetricsValue
      } else {
        return item.currentMetricsValue + '（' + item.metricsRange + '）'
      }
    }
  }
}

export default common

